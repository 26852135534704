
import { defineComponent, onUpdated, reactive, ref, toRefs } from "vue";
import SatSearchInput from "@/components/SatSearchInput.vue";
import SatCardCarousel from "@/components/carousel/SatCardCarousel.vue";
import SatCarousel from "@/components/carousel/SatCarousel.vue";
import SatCard from "@/components/carousel/SatCard.vue";
import SatLogoFiltered from "@/components/SatLogoFiltered.vue";
import useTeamRepositories from "@/_composables/useTeamRepositories";
import useAthleteRepositories from "@/_composables/useAthleteRepositories";
import { AthleteQueryDto, IAthlete, TeamQueryDto } from "@/_modules/types";
import { LoadingOutlined } from "@ant-design/icons-vue";
import SatIcon from "@/components/SatIcon.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    SatSearchInput,
    SatCardCarousel,
    SatCarousel,
    SatCard,
    LoadingOutlined,
    // SatLogoFiltered,
    SatIcon,
  },
  setup() {
    const { t,locale } = useI18n({
      useScope: "global",
    });
    const store = useStore();

    // const searching = ref<boolean>(false)
    const teamsId = ref([] as number[]);
    const state = reactive({
      searching: false,
      results: [] as IAthlete[],
      pageSearch: 1,
      lastPageSearch: 1,
      sizeSearch: 10,
      query: {},
      skeletonLoad: false,
      loadingSearchFilter: false,
    });
    const { repositories: teams } = useTeamRepositories(
      ref(reactive({ page: 1, size: 10 }) as TeamQueryDto)
    );
    const { getAthleteOverview, fetchingOverview, fetchingOverviewByTeam } =
      useAthleteRepositories();

    const handleSearch = async (search: AthleteQueryDto) => {
      const { type, ...other } = search;
      if (!other.name && type !== "filter") {
        state.searching = false;
        return;
      }
      state.searching = true;
      if (type === "filter") {
        state.query = other;
      }
      state.skeletonLoad = true;
      state.results =
        (await getAthleteOverview({
          page: 1,
          size: state.sizeSearch,
          ...other,
        })) ?? [];
      console.log("handleSearch :: ", state.results);
      state.skeletonLoad = false;
    };

    const onNextAthlete = async (props: any) => {
      if (props.page) {
        state.lastPageSearch = props.page;
      }
      const res =
        (await getAthleteOverview({
          ...props,
          ...state.query,
        })) ?? [];
      state.results = [...state.results, ...res];
    };
    // onUpdated(() => {
    //   console.log('Athletes state :: ', state)
    // })
    const onLogoFilteredChanged = (val: number[]) => {
      // console.log('onLogoFilteredChanged ', val, Object.values(val))
      teamsId.value = Object.values(val);
    };

    const onLoadMoreSearchAndFilter = async () => {
      state.loadingSearchFilter = true;
      const res = await getAthleteOverview({
        page: state.lastPageSearch + 1,
        size: state.sizeSearch,
        ...state.query
      });
      // setTimeout(() => {
      //   state.loadingSearchFilter = false;
      // }, 1000);
      if (res) {
        state.results = [...state.results, ...res];
      }
      state.lastPageSearch += 1;
      state.loadingSearchFilter = false;
    };

    return {
      t,
      store,
      teams,
      teamsId,
      handleSearch,
      ...toRefs(state),
      onLogoFilteredChanged,
      fetchingOverview,
      fetchingOverviewByTeam,
      onNextAthlete,
      onLoadMoreSearchAndFilter,
    };
  },
});
